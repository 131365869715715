<template lang="html">
  <div class="row">
    <div
      v-for="(letter, index) of letters"
      :key="index"
      :class="guess.Ratings[index]"
    >
      {{ letter }}
    </div>
  </div>
</template>

<script>
export default {
  props: ["guess"],
  data: () => ({
    letters: [],
  }),
  created() {
    if (this.guess) {
      this.letters = this.guess.Guess.toUpperCase().split("");
    }
  },
};
</script>

<style lang="css" scoped>
.row {
  padding: 1em;
  display: flex;
}
.row div {
  padding: 1em;
  border: solid black 1px;
  margin-right: 1em;
}
.Green {
  background-color: LightGreen;
}
.Brown {
  background-color: Tan;
}
.Gray {
  background-color: Silver;
}
</style>
