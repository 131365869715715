<template>
<div id="app">
  <div class="game-board">
    <h2>Five Letter Words</h2>
    <div class="guesses">
      <GuessRow v-for="(guess, index) in guesses" :key="index" :guess="guess" />
    </div>
    <div class="current" v-if="!showNew">
      <div class="row">
        <div class="cell">
          <input type="text" v-model="input[0]" disabled />
        </div>
        <div class="cell">
          <input type="text" v-model="input[1]" disabled />
        </div>
        <div class="cell">
          <input type="text" v-model="input[2]" disabled />
        </div>
        <div class="cell">
          <input type="text" v-model="input[3]" disabled />
        </div>
        <div class="cell">
          <input type="text" v-model="input[4]" disabled />
        </div>
      </div>
    </div>
    <div class="buttons">
      <div class="alphabet" v-if="!showNew">
        <button type="button" v-for="(letter, index) in letters" :key="index" @click="addLetter(letter)">
          {{ letter }}
        </button>
      </div>
      <button type="button" v-if="input.length > 0" @click="back">
        &lt; Back
      </button>
      <button type="button" v-if="input.length === 5" @click="sendGuess">
        Guess
      </button>
      <button type="button" v-if="showNew" @click="newGame">New Game</button>
    </div>
    <div class="game-number" v-if="!showNew" @click="toggleIndex">
      <strong>Game Number:</strong>
      <input type="number" v-model="word.Index" :disabled="indexDisabled" />
      <button v-if="!indexDisabled" @click="changeIndex">Change</button>
      <button v-if="!indexDisabled" @click="cancelIndex">Cancel</button>
    </div>
    <div class="error-message" v-if="errorMessage">{{ errorMessage }}</div>
    <div class="hint-header" v-if="guesses.length && hints.length">
      <strong>Hints</strong>
      <input type="checkbox" :checked="showHints" @click="toggleHints" />
    </div>
    <div class="hints" v-if="showHints && hints.length">
      <div v-for="(word, index) in hints" :key="index">{{ word }}</div>
    </div>
    <div class="footer">
      Constructed by Jeff Rossi
      &lt; <a href="mailto:Jeffrey.Rossi@snapraise.com">Jeffrey.Rossi@snapraise.com</a> &gt;
    </div>
  </div>
</div>
</template>

<script>
import GuessRow from "./components/GuessRow.vue";

export default {
  name: "App",
  components: {
    GuessRow,
  },
  data: () => ({
    word: {},
    current: {
      Green: [],
      Brown: [],
      Gray: [],
    },
    index: 0,
    input: [],
    guesses: [],
    alphabet: "ABCDEFGHIJKLMNOPQRSTUVWXYZ",
    letters: [],
    hints: [],
    base: "https://five-letter-api.jeff-rossi.com/",
    // base: 'http://localhost:4000/',
    timeout: null,
    showNew: false,
    errorMessage: null,
    showHints: false,
    indexDisabled: true,
  }),
  methods: {
    async loadWord() {
      const {
        base
      } = this;
      const result = await fetch(`${base}word/random`);
      if (result.ok) {
        const {
          Word,
          Index
        } = await result.json();
        this.word = {
          Word,
          Index,
        };
      }
    },
    setup() {
      this.input = [];
      this.hints = [];
      this.guesses = [];
      this.current.Green = [];
      this.current.Brown = [];
      this.current.Gray = [];
      for (let i = 0; i < 5; i++) {
        this.current.Green[i] = "";
        this.current.Brown[i] = [];
      }
      this.errorMessage = null;
    },
    addLetter(letter) {
      if (this.input.length >= 5) return;
      if (this.timeout) clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.input.push(letter);
      }, 100);
    },
    back() {
      if (this.timeout) clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.input.pop();
      }, 100);
      this.errorMessage = null;
    },
    async sendGuess() {
      const {
        input,
        base
      } = this;
      const {
        Index
      } = this.word;
      this.errorMessage = null;
      const Guess = input.join("").toLowerCase();
      const result = await fetch(`${base}word/rate`, {
        method: "POST",
        mode: "cors",
        body: JSON.stringify({
          Guess,
          Index,
        }),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });
      if (result.ok) {
        const {
          Ratings
        } = await result.json();
        this.guesses.push({
          Guess,
          Ratings,
        });
        this.input = [];
        let letter, rating;
        for (let i = 0; i < Ratings.length; i++) {
          letter = Guess[i];
          rating = Ratings[i];
          switch (rating) {
            case "Green":
              this.current.Green[i] = letter;
              break;
            case "Brown":
              if (!this.current.Brown[i].includes(letter)) {
                this.current.Brown[i].push(letter);
              }
              break;
            case "Gray":
              if (!this.current.Gray.includes(letter)) {
                this.current.Gray.push(letter);
              }
              break;
            default:
              break;
          }
        }
        if (!(Ratings.includes("Brown") || Ratings.includes("Gray"))) {
          this.hints = [];
          this.showNew = true;
        } else {
          this.getHints();
        }
      } else {
        const {
          message
        } = await result.json();
        this.errorMessage = message;
      }
    },
    async getHints() {
      const {
        current,
        base
      } = this;
      try {
        const result = await fetch(`${base}word/available`, {
          method: "POST",
          mode: "cors",
          body: JSON.stringify(current),
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        });
        if (result.ok) {
          const {
            Words
          } = await result.json();
          this.hints = Words;
        }
      } catch (e) {
        console.log(e);
      }
    },
    newGame() {
      this.setup();
      this.loadWord();
      this.showNew = false;
    },
    toggleHints() {
      this.showHints = !this.showHints;
    },
    toggleIndex() {
      if (!this.indexDisabled) return;
      this.indexDisabled = false;
    },
    async changeIndex() {
      const {
        base
      } = this;
      const {
        Index
      } = this.word;
      const result = await fetch(`${base}word/${Index}`);
      if (result.ok) {
        const {
          Word
        } = await result.json();
        if (Word) {
          this.word = {
            Word,
            Index,
          };
          this.indexDisabled = true;
          this.setup();
        } else {
          this.errorMessage = `No word found for number ${Index}`;
        }
      }
    },
    cancelIndex(e) {
      e.stopPropagation();
      this.indexDisabled = true;
    },
  },
  created() {
    this.loadWord();
    this.letters = this.alphabet.split("");
    this.setup();
  },
};
</script>

<style>
.game-board {
  width: 33em;
  margin: auto;
}

h2 {
  margin-left: 1em;
}

.row {
  display: flex;
}

.cell {
  width: 2em;
  padding: 1em;
}

.cell input {
  width: 1em;
  padding: 1em;
  border: solid black 1px;
}

.buttons,
.footer,
.hint-header,
.game-number {
  padding: 1em;
}

.alphabet {
  display: flex;
  flex-wrap: wrap;
}

.buttons button {
  padding: 1em;
  border: solid black 1px;
  margin: 0 1em 1em 0;
  cursor: pointer;
  background-color: #ccc;
}

.buttons button:hover {
  border: black red 2px;
  background-color: #ddd;
}

.hints {
  max-height: 250px;
  overflow-y: auto;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 2em;
  padding: 1em;
}

.hints div {
  margin: 0 2em 1em 0;
}

.error-message {
  border: dashed black 3px;
  background-color: orange;
  padding: 1em;
  font-weight: bold;
}

.game-number input {
  width: 5em;
  padding: 1em;
  border: solid black 1px;
  cursor: pointer;
}

.game-number strong {
  margin-right: 1em;
}

.game-number button {
  padding: 1em;
  border: solid black 1px;
  margin-left: 1em;
}
</style>
